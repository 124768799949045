import React from "react"
import { Heading, Box, Text, Stack, Divider } from "@chakra-ui/react"

import ResponsiveBlock from "../components/shared/responsive-block"
import Header from "../components/shared/header"
import SEO from "../components/shared/SEO"

const WhitepaperPage = () => {
    return (
        <>
            <SEO title="Whitepaper" />
            <Header />
            <ResponsiveBlock pb={"300px"}>
                <Stack spacing={4}>
                    <Heading fontSize="md">Masterlock Whitepaper</Heading>
                    <Heading fontSize="sm" pt={4}>
                        Summary
                    </Heading>
                    <Text>
                        The Masterlock App chooses its picks based on the idea
                        that oddsmakers are wrong somewhat consistently and
                        predictably. The spread is the best indicator of
                        oddsmakers valuation of a team, so instead of attempting
                        to figure out the winner of each game, we just need to
                        figure out if oddsmakers overvalue or undervalue certain
                        teams in terms of their spread valuation.
                    </Text>
                    <Divider />

                    <Heading fontSize="sm" pt={8}>
                        Definitions:
                    </Heading>
                    <Text>
                        <b>Spread:</b> <i>noun</i> The spread is an attempt of
                        an oddsmaker to bring a matchup as close to a 50%/50% as
                        possible. It is the number of points that the oddsmaker
                        feels each team will win or lose by. For example, if the
                        Browns are an underdog by -7 points, meaning that they
                        are projected to lose by 7, then they will have a spread
                        of +7.
                    </Text>
                    <Text>
                        <b>Cover:</b> <i>verb</i> To cover is to win against the
                        spread. If a team scores more points than the other team
                        plus their spread, they have covered. For example, if
                        the Browns are an -7 point underdog, and they win by 10
                        points, they have covered the spread by 10 - (+7) = +3,
                        which is a won bet.
                    </Text>
                    <Text>
                        <b>Oddsmaker:</b> <i>noun</i> Sometimes referred to here
                        as "Vegas", the oddsmaker is the individual or
                        organization who sets the spread and prices for a
                        matchup based on their data. It is in the oddsmakers
                        best interest to make bettors lose as many bets as
                        possible.
                    </Text>
                    <Text>
                        <b>Price:</b> <i>noun</i> The price is the multiple of
                        winnings from a bet. A spread's price is usually -110.
                        This means that if you bet $11, your payout would be
                        $10. It isn't exactly even so that the oddsmaker can
                        make money more consistently.
                    </Text>
                    <Heading fontSize="sm" pt={4}>
                        Background
                    </Heading>
                    <Text>
                        The idea of Masterlock came to be as a result of a group
                        of friends who were avid sports bettors. The hypothesis
                        of Masterlock was based on the idea that a team who more
                        consistently has covered the spread would continue to do
                        so in the future.
                    </Text>
                    <Text>
                        The second hypothesis was that a team who consistently
                        did <i>not</i> cover the spread would continue to do so
                        in future games.
                    </Text>
                    <Text>
                        This hypothesis is based on the concept that oddsmakers
                        are never 100% correct; they use a sophisticated dataset
                        to build a highly accurate model, and the effort to
                        recreate such a model is too much for most
                        non-enterprise teams.
                    </Text>
                    <Text>
                        The catch, however, is that this data leads to
                        predictable outcomes, so we don't need that model. We
                        need to track their model. The spread of any given
                        matchup is as close to a 50-50 bet as an oddsmaker can
                        produce. This can be deduced from the price of these
                        bets being around -110 on either side (which we will
                        consider even for this explanation).
                    </Text>
                    <Text>
                        This leads to the reasonable assumption that a team that
                        has covered consistently is undervalued in the
                        oddsmakers model, since the oddsmakers are consistently
                        estimating their score to be lower than it actually is.
                    </Text>
                    <Heading fontSize="sm" pt={4}>
                        Implementation
                    </Heading>
                    <Text>
                        In order to take advantage of this predictability, our
                        solution is multifaceted:
                    </Text>
                    <Text>
                        The first step is to gather matchups for the given day.
                        We use a simple API to gather and clean that data.
                    </Text>
                    <Text>
                        The second step is to gather each team's win percentage
                        against the spread. We're going to call this their{" "}
                        <b>ATS Record</b>, or <b>ATS Win Percent</b>. We
                        calculate this by taking their number of games that each
                        team has covered, divided by the total number of games
                        they have played. We do not count a push (or a tie) as a
                        win.
                    </Text>
                    <Text>
                        Once we have both of these sets of data, the third step
                        is to compare, for each matchup, each team's win
                        percentages and find the difference. We subtract the two
                        win percentages and then take the absolute value of the
                        difference.
                    </Text>
                    <Text>
                        Now that we have the win percent differential (which we
                        will refer to as just "the differential"), step four is
                        to sort them in order of highest differential and assign
                        each a label. The best possible label is a{" "}
                        <Box as="span" color="magenta">
                            Masterlock.
                        </Box>{" "}
                        This is given to differentials of &gt;55%. Then we have{" "}
                        <Box as="span" color="green.500">
                            Great,
                        </Box>{" "}
                        <Box as="span" color="green.400">
                            Good,
                        </Box>{" "}
                        <Box as="span" color="orange.400">
                            Decent,
                        </Box>{" "}
                        and{" "}
                        <Box as="span" color="red.600">
                            Cringe,
                        </Box>{" "}
                        in descending order of differential.
                    </Text>
                    <Text>
                        Due to the price of spreads, in order to consistently be
                        making money, we need a win percentage of &gt;55%.
                    </Text>
                    <Text>
                        We can take this differential into account to make
                        picks. In the future, we will be implementing a way to
                        see how a team's spread has changed and its trend. If a
                        team is trending towards covering by more points per
                        game, then we can assign them a higher score.
                    </Text>
                    <Heading fontSize="sm" pt={4}>
                        Solution
                    </Heading>
                    <Text>
                        As bettors, we can use this data to help us make picks.
                    </Text>
                    <Text>
                        We'll never be 100% correct, because you can't predict
                        things that happen during every game, but as long as
                        Vegas is consistent, we should be able to win.
                    </Text>
                    <Text>
                        At the end of the day, we'll always need to come
                        together as a community to decide what looks good and
                        what doesn't. That's why the Locksmiths exist, so if
                        you'd like to be a part of it, join our Discord, and
                        let's start picking some locks.
                    </Text>
                </Stack>
            </ResponsiveBlock>
        </>
    )
}

export default WhitepaperPage
